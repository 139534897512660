.sm-adbox {
    border:1px solid #ddd;
    min-height: 180px;
    width: '400px';
    background-color: #fff;
    border-radius: 3px;
    padding: 15px 20px;
    box-shadow: 0px 0px 5px #ccc;
    /* float: right; */
}
.adp-title {
    font-size: 18px;
    color: #bf4343;
}
.adp-desc {
    font-size: 14px;
    min-height: 80px;
}
.adp-url {
    margin-bottom: 5px;
}
.adp-url b {
    color: black;

}

.sm-error {
    color:red;
    font-size: 13px;
}
.sm-icon {
    height: 17px;
    width: 17px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.loadr-outer {
    position: fixed;
    top: 0 !important; left: 0 !important;
    height: 100% !important;
    width: 100% !important;
    z-index: 100001;
    
}

.loadr-ovr {
    position: absolute;
    background-color: white;
    height: 100% !important;
    width: 100% !important;
    opacity: 0.6;
    z-index: 100002;
    
}
.loadr-body {
    position: absolute;
    height: 100% !important;
    width: 100% !important;
    z-index: 100003;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sm-drop-zone {
    min-height: 200px;
    width: 220px;
    border:1px dashed #666;
    background-color: #f9f9f9;
}

.sm-drop-zone2 {
    width: 270px;
    min-height: 165px;
    border:1px dashed #666;
    background-color: #f9f9f9;
}

.sm-drop-zone3 {
    width: 400px;
    min-height: 133px;
    border:1px dashed #666;
    background-color: #f9f9f9;
}

.sm-drop-zone3 img { 
    width: 100%;
}

.sm-drop-social-img {
    width: 120px;
    height: 120px;
    border:1px dashed #666;
    background-color: #f9f9f9;
}

.sm-input {
    position: absolute;
    background-color: #ccc;
    left: 0; top: 0;
    height: 35px;
    opacity: 0 ;
    width: 100%;
}

.sm-social1 {
    height:80px;
    width: 350px;
    background: #fff;
    box-shadow: 15px 15px 25px #999;
    /* padding: 12px 18px; */
    border:1px solid #f1f1f1;
    border-radius: 5px;
}

.sm-social1-img {
    width: 50px;
    height: 50px;
}
.pro-img {
    border-radius: 5px;
    width: 55px !important;
    /* height: 120px; */
}
.sm-soc-title {
    font-size: 16px;
    margin-bottom: 4px;
}
.sm-soc-desc {
    font-size: 13px;
    line-height: 1.2;
}

.sm-social2 {
    height:80px;
    width: 360px;
    /* background: #fff; */
    /* box-shadow: 15px 15px 25px #999; */
    /* padding: 12px 18px; */
    /* border:1px solid #f1f1f1; */
    border-radius: 5px;
}

.pro2-img {
    border-radius: 50px;
    width: 55px !important;
    /* height: 120px; */
}

.pro2-msg {
    background-color: #3F54FF;
    border-radius: 20px;
    padding: 10px 15px;
    position: relative;
}

.pro2-msg .sm-soc-title, .pro2-msg .sm-soc-desc {
    color: #fff;
}

.pro2-msg:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: transparent #3F54FF;
    border-width: 12px 0 12px 15px;
    top: 51%;
    right: -12px;
    margin-top: -14px;
}

.img-outer {
    border: 2px solid #3F54FF;
    padding: 2px;
    border-radius: 50%;
}

.sm-native-container {
    border: 1px solid #ddd;
}
.native-img {
    width: 100%;
}
.sm-native-container label {
    color: #3F54FF;
    text-transform: uppercase;
    font-size: 11px;
    margin-bottom: 10px;
}
.sm-tbl {
    width: 100%;
}
.sm-tbl td, .sm-tbl th {
    padding: 12px 0px;
}

.sm-tag {
    line-height: 1.3;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 10px;
    margin-right: 5px;
}

.sm-bg-light {
    background-color: #f5f6f8;
}

.sm-title .modal-title{
    width: 100%;
    font-size: 19px;
}
.table-ulogs .table td{padding: 1rem !important;}

.wl-row:hover {
    background: #FCF3F4;
}

.amt-pop-ul {
    width: 220px;
}

.amt-pop-ul li {
    font-size: 12px;
}
.amt-pop-ul li span {
    font-weight: 600;
}   
.sm-ico-sort {
    color: #8094AE;
    cursor: pointer;
}   
.sm-ico-sort:hover {
    color: #c9d5e5;
} 